import Typography from 'typography'
import Wordpress2016 from 'typography-theme-wordpress-2016'

Wordpress2016.overrideThemeStyles = () => {
  return {
    'a.gatsby-resp-image-link': {
      boxShadow: `none`,
    },
    pre: {
      lineHeight: 'inherit',
      fontFeatureSettings: 'normal',
    },
    code: {
      fontFamily: 'Menlo,Monaco,"Courier New",Courier,monospace',
      fontSize: '1em',
      color: '#333',
      background: 'rgba(0,0,0,.05)',
      padding: '3px 4px',
      margin: '0 2px',
    },
    '.code-snippet': {
      overflowX: 'auto',
      backgroundColor: 'rgb(246, 248, 250)',
      paddingLeft: '8px',
      paddingRight: '8px',
    },
    figcaption: {
      ...typography.scale(-1 / 5),
      textAlign: 'center',
      color: '#333',
      margin: '0 2em',
    },
  }
}

delete Wordpress2016.googleFonts

const typography = new Typography(Wordpress2016)

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
